import UltraLedDiagram from "../../../../docs/_images/hw_ff_ultra-led.png";
import UltraLedSchematics from "../../../../docs/_images/hw_ff_ultra-led_schem.png";
import UltraDividerDiagram from "../../../../docs/_images/hw_ff_ultra-divider.png";
import UltraDividerSchematics from "../../../../docs/_images/hw_ff_ultra-divider_schem.png";
import * as React from 'react';
export default {
  UltraLedDiagram,
  UltraLedSchematics,
  UltraDividerDiagram,
  UltraDividerSchematics,
  React
};